module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-embedder"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Notes | Kyle Gill","short_name":"Notes","start_url":"/","background_color":"#E6F1FF","theme_color":"#FCD34D","display":"minimal-ui","icon":"src/img/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3aa0a3f143b37822dcf899e6c3bda146"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
